<template>
  <div>
    <Breadcrumbs
      :title="mainObject.sname + ' - ' + mainObject.spname"
      class="text-blue"
    />

    <b-row>
      <b-col class="m-0">
        <b-card class="custom-font-30 text-blue text-center custom-modal-card">
          <div class=""><b>Home Page</b></div>
          <b-img
              thumbnail
              fluid
              class="img_custom_style"
              style="max-height: 410px"
              :src="getInsightHomePage(mainObject.insight_id)"
              alt="Banner"
              @click="
                openImageModal(getInsightHomePage(mainObject.insight_id))
              "
              @error="$event.target.src = `../no_image.png`"
            ></b-img>
        </b-card>
      </b-col>
      
      <b-col cols="8" class="m-0">
        <ad-details-vue
          :mainObject="mainObject"
          :isMobile="isMobile"></ad-details-vue>
      </b-col>
    </b-row>

<b-row>
   <b-card class="m-2 custom-modal-card">
              <b-tabs pills class="nav-container">
                <b-tab v-for="(item, index) in insightSteps" :key="index" :title="item.title">
                 <b-row class="p-3" style="max-height: 60vh;overflow-y: scroll;">
                    
                    <b-card
                      style="border-radius: 0.313vw;width: 15rem;"
                      v-for="(step, index) in item.imagesUrl"
                      :key="index"
                      type="gradient-red"
                      class="m-1"
                    >
                          <b-img
                          thumbnail
                          fluid
                          class="img_custom_style-2"
                          :src="step"
                          alt="Banner"
                          @click="openImageModal(step)"
                          @error="$event.target.src = `../no_image.png`"
                        ></b-img>
              </b-card>
              <b-col cols=3 class="m-0">
              <b-card no-body>
               
                <video  v-show="item.title == 'Video'"
            class="video_tag"
           :src="item.videoUrl" 
            id="videocontainer"
            height="90%"
            controls
            style="max-height: 60vh;width: -webkit-fill-available;"
          >
          </video>
                    <!-- <video>
                      <source :src="item.videoUrl" type="video/mp4" />
                    </video> -->
              </b-card>
              </b-col>
                 </b-row>
                
                </b-tab>
              </b-tabs>
            </b-card>
</b-row>
<b-row class="p-1">
    <b-card no-body class="m-1 custom-modal-card">
              <b-tabs pills card class="nav-container">
                <b-tab title="Hosts" active>
                  <b-card-text>
                    <ul class="custom-font-16 custom-list">
                      <li v-for="(item, index) in logsObject.hosts" :key="index" class="list-item">
                        {{ item }}
                      </li>
                    </ul>
                  </b-card-text>
                </b-tab>
                <b-tab title="Urls">
                  <b-card-text>
                    <ul class="custom-font-16 custom-list">
                      <li v-for="(item, index) in logsObject.urlChain" :key="index" class="list-item">
                        {{ item }}
                      </li>
                    </ul>
                  </b-card-text>
                </b-tab>
                <b-tab title="Logs">
                  <b-card-text>
                    <ul class="custom-font-16 custom-list">
                      <li v-for="(item, index) in logsObject.logs" :key="index" class="list-item">
                        {{ item }}
                      </li>
                    </ul>
                  </b-card-text>
                </b-tab>
            
              </b-tabs>
            </b-card>
</b-row>
  </div>
 
</template>
  
  <script>
import TranscationService from "../../services/TranscationService";
import InsightService from "../../services/InsightService";
import NetworkTypeService from "../../services/NetworkTypeService";
import config from "../../services/globalLit";
import AdDetailsVue from '../components/AdDetails.vue';

export default {
  components: {
    AdDetailsVue
  },
  data() {
    return {
      networks: [],
      defaultPayload: {
        insight_id: "",
      },
      comment_fields: ["_id", "show_details"],
      currentComment: [],
      commentList: [],
      mainObject: {
        adDetailsTable: [],
      },
      logsObject: {
        logs: [],
        hosts: [],
        urlChain: [],
      },
      adDetailsTable2: [
        {
          trxId: "66bee23438ac1b7ba884c74b",
          trx_name: "Test provider - testservice",
          netOp: "Macdonald",
          date: "2021-02-11",
        },
      ],

      showModal: "",

      tableKeys: {
        adUrl: "Ad URL",
        finalUrl: "Final URL",
        netType: "Network|OP", //1: wifi 2: op1 3: op2
        channel: "Channel",

        paymentInfo: "Payment Info",
        pricePointInfo: "Pricepoint Info",
        createdAt: "Date",
      },
      fields: ["key", "value"],
      adDetailsTable: [{ key: "action", value: "action" }],
      modalimageurl: "",
      show_all_commnets: false,
      comment_text: "",
      public_service_url: "",
      userinfo: {},
      load_commnet: false,
      networkTypeArray: [],
      activeSection: "",
      insightSteps: []
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    },
  },
  mounted() {
    const insight_id = this.$route.query.insight_id;

    if (insight_id) {
      this.defaultPayload = {
        insight_id: insight_id,
      };
      this.getNetworks();
      this.init();
    }
  },
  beforeDestroy() {
    console.log("distroyed openTrx");
    this.$root.$off("openTrx");
  },
  methods: {
    CopyShareUrl(text) {
      const el = document.createElement("textarea");
      el.addEventListener("focusin", (e) => e.stopPropagation());
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$toasted.show("Copied", {
        theme: "outline",
        position: "bottom-center",
        type: "success",
        duration: 2000,
      });
    },
    async ExportTrx(id) {
      let url = config.exportTrnx + `${id}`;
      const a = document.createElement("a");
      a.href = url;
      a.download = "transaction.pdf";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    openImageModal(data, type) {
      this.modalimageurl = data;

      // window.open('', '_blank', `width=${screen.width},height=${screen.height}`).document.write(`<html><head><title>Image Popup</title></head><body><img src="${this.modalimageurl}" style="max-width: 100%; height: auto;"></body></html>`);
      const popupWindow = window.open(
        "",
        "_blank",
        `width=${screen.width},height=${screen.height}`
      );
      popupWindow.document.write(
        `<html><head><title>Image Popup</title></head><body><img src="${this.modalimageurl}" style="max-width: 100%; height: auto;"></body></html>`
      );
      popupWindow.document.addEventListener("keydown", function (event) {
        if (event.key === "Escape" || event.keyCode === 27) {
          popupWindow.close();
        }
      });
    },
    getInsightHomePage(id) {
      return config.getInsightTestHome + `/${id}`;
    },

    async getLogs() {
      let insight_id = this.defaultPayload.insight_id;
      let response = await InsightService.insightLogDetail(insight_id);
      if (response.result) {
        this.logsObject.hosts = response.data.hosts;
        this.logsObject.urlChain = response.data.urlChain;
        this.logsObject.logs = response.data.logs;
      }
    },
    async getNetworks() {
      let country = localStorage.getItem("country");
      let netRes = await NetworkTypeService.getAllNetworkType(country);
      if (netRes.result) {
        this.networks = netRes.data;
      }
    },
    async getInsightData() {
      let payload = {
        ...this.defaultPayload,
      };
      let response = await InsightService.insightTrxDetail(payload);
      if (response.result) {
        this.mainObject = response.data;
        this.mainObject.adDetailsTable = [
          {key: "Service Name", value: `${this.mainObject.sname} - ${this.mainObject.spname}`},
        ];
        let serviceDetail = response.data.sdoc;
        serviceDetail = {
          ...serviceDetail,
          ...this.mainObject,
        };
       
        let trxKey = Object.keys(serviceDetail);

        for (let i = 0; i < trxKey.length; i++) {
          if (this.tableKeys[trxKey[i]]) {
            let rec = {
              key: this.tableKeys[trxKey[i]],
              value: serviceDetail[trxKey[i]],
            };

            if (rec.key == "Network|OP") {
              rec.value = this.networks[serviceDetail[trxKey[i]]];
            }
            this.mainObject.adDetailsTable.push(rec);
          }
        }

        this.mainObject.adDetailsTable.push({
          key: "Export",
          value: "Export",
        });
        
      }
    },
    async getInsightsSteps() {
      let insight_id = this.defaultPayload.insight_id;
      let response = await InsightService.getInsightsSteps(insight_id);
      if (response.result) {
         this.insightSteps = response.data;
      }
    },
    async init() {
      console.log("init call");
      this.getInsightData();
      this.getInsightsSteps();
      this.getLogs();
    },
    async getNetworkType() {
      let response = await NetworkTypeService.getAllNetworkType();
      let networkTypeArray = [];
      for (const [key, value] of Object.entries(response.data)) {
        networkTypeArray.push({ id: key, name: value });
      }
      this.networkTypeArray = networkTypeArray;
    },
    toggleDetails(row) {
      if (row.detailsShowing) {
        row.toggleDetails();
      } else {
        this.currentComment.forEach((item) => {
          this.$set(item, "_showDetails", false);
        });
        this.$nextTick(() => {
          row.toggleDetails();
        });
      }
    },
    mailToLink(url) {
      let body = `
Dear Marketing Team,

  I am writing to bring to your attention an issue related to a recent marketing that was conducted for the brand.

  As a concerned party, I would like to request that you review this matter and take the necessary corrective action to ensure that your advertising and
  marketing practices are fully compliant with all relevant regulatory requirements.

  For your reference, here is the link to the advertising page that was used during the test:
  <a href="${url}">${url}</a>.

  If you require further information or clarification on this matter, please do not hesitate to reach out to me.

  Thank you for your prompt attention to this issue.

Sincerely,
${this.userinfo.username}
${this.userinfo.email}
`.replace(/<[^>]*>/g, "");
      return body;
    },
    SendMail(data) {
      let from = this.userinfo.email;
      let subject = "Concern regarding recent marketing";
      let body = data;
      let mailtoLink =
        "mailto:?subject=" +
        encodeURIComponent(subject) +
        "&body=" +
        encodeURIComponent(body) +
        "&from=" +
        from;
      // Set the Content-Type header to "text/html"
      mailtoLink += "&Content-Type=text/html";
      // Add the "from" parameter to the mailto link if the "from" address is specified
      if (from) {
        mailtoLink += "&from=" + encodeURIComponent(from);
      }
      return mailtoLink;
    },
  },
};
</script>
  
<style scoped>
.hidden_header {
  display: none;
}
div {
  margin: auto;
  width: 100%;
}
.img_custom_style {
  min-height: 200px !important;
  min-width: 100px;
  max-height: 430px !important;
  max-width: 100%;
}

.img_custom_style-2 {
  min-height: 180px;
  min-width: 100px;
  max-height: 420px;
  max-width: 100%;
}

.img-custom-multiple {
  min-width: 80px;
  max-width: 100px;
}

.img_custom_style:hover,
.img_custom_style-2:hover {
  cursor: pointer;
  border: 2px solid #274aa6;
}

.custom-modal-card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px !important;
  border-radius: 10px;
  word-wrap: break-word;
}

.custom-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.list-item {
  position: relative;
  padding: 10px 0;
}

.list-item:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom: 1px dashed #000;
  margin-top: 5px;
  width: 30%;
}

.custom-head-text {
  margin-left: 2rem;
}

hr {
  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-left: 2rem;
  border-top: 1px solid #909090;
}

.copyurl-btn-container {
  display: flex;
  align-items: center;
  margin-left: 1.2rem;
}

@media (max-width: 767px) {
  .custom-head-text,
  hr {
    margin-left: 0.5rem;
  }

  .custom-modal-card:last-child {
    width: 95%;
    padding: 0.5rem;
  }

  .img_custom_style-2 {
    margin: 10px 0px 10px 0px;
    max-height: 120px;
    max-width: 50px;
  }

  .img-custom-multiple {
    max-height: 120px;
    max-width: 50px;
  }

  .list-item:not(:last-child)::after {
    width: 100%;
  }

  .copyurl-btn-container {
    margin-left: 0;
    margin-top: 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
  }

  .copyurl-btn-container button {
    margin-top: 0.5rem;
  }
}
</style>
<style>
.custom-modal-card .nav-pills li .nav-link.active,
.custom-modal-card .nav-pills .show > .nav-link {
  background-color: #274aa6 !important;
  color: white !important;
  font-size: 18px;
}

.custom-modal-card .nav-item a {
  color: #274aa6 !important;
  font-size: 18px;
}

.card-header-pills {
  margin-left: 0;
}

@media (max-width: 767px) {
  .custom-modal-card .nav-pills li .nav-link.active,
  .custom-modal-card .nav-pills .show > .nav-link,
  .custom-modal-card .nav-item a {
    font-size: 16px !important;
    padding: 10px !important;
  }
  .nav {
    display: flex !important;
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
  }

  .adDetail-table tr {
    -webkit-box-shadow: 0px;
    box-shadow: none;
  }

  .adDetail-table td {
    padding: 0.5rem 0.75rem;
  }

  .table.b-table.b-table-stacked-md > tbody > tr > [data-label]::before {
    text-align: left;
  }

  /* .insight-service-cont table {
      text-align: left !important;
    } */
}
</style>